import styled from "@emotion/styled";
import { fontRoboto, transientOptions } from "@product/scmp-sdk";
import { SwiperSlide } from "swiper/react";

import { BaseLink } from "scmp-app/components/common/base-link";
import { Swiper } from "scmp-app/components/swiper";

import IconArrowNextMobile from "./icon-arrow-next-mobile.svg?url";
import IconArrowPreviousMobile from "./icon-arrow-previous-mobile.svg?url";
import IconDot from "./icon-dot.svg";

type StyledBaseLinkProps = {
  $isSelected: boolean;
};

export const StyledBaseLink = styled(BaseLink, { ...transientOptions })<StyledBaseLinkProps>`
  display: flex;
  align-items: center;

  color: #000000;
  font-weight: ${props => (props.$isSelected ? 700 : 400)};
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 22.4px;
`;

export const Container = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  block-size: 22.4px;
  margin-block: 20px 20px;

  &:empty {
    display: none;
  }
`;

export const Label = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 22.4px;
  white-space: nowrap;
`;

export const StyledSwiper = styled(Swiper)`
  position: relative;

  margin: 0;
  padding: 0;

  overflow-y: visible;

  .swiper-wrapper {
    block-size: auto;
  }

  .swiper-button-prev,
  .swiper-button-next {
    inset-block-start: 0;

    inline-size: 36px;
    block-size: 19px;
    margin: 0;

    border-radius: 0;

    background-color: transparent;
    background-size: unset;

    box-shadow: none;

    opacity: 1;
  }

  .swiper-button-prev {
    inset-inline-start: 0;

    margin-inline-start: -9px;

    background-image: url(${IconArrowPreviousMobile});
  }

  .swiper-button-next {
    inset-inline-end: 0;

    margin-inline-end: -9px;

    background-image: url(${IconArrowNextMobile});
  }
`;

type StyledSwiperSlideProps = {
  $isSuperTopic: boolean;
};

export const StyledSwiperSlide = styled(SwiperSlide, {
  ...transientOptions,
})<StyledSwiperSlideProps>`
  &.swiper-slide {
    display: flex;
    align-items: center;

    inline-size: auto;

    &:not(:last-child) {
      margin-inline-end: ${props => (props.$isSuperTopic ? "12px" : "20px")};
    }
  }
`;

export const SwiperContainer = styled.div`
  min-inline-size: 0;
`;

export const StyledDot = styled(IconDot)`
  margin-inline-start: 12px;
`;
