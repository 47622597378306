import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { hundredTopTablesContent$key } from "scmp-app/queries/__generated__/hundredTopTablesContent.graphql";

import { ActionBar, Container, CoverImage, Headline, StyledEntityLink } from "./styles";

type Props = {
  className?: string;
  reference: hundredTopTablesContent$key;
} & ContentItemRenderVariantProps;

export const HundredTopTables = forwardRef<HTMLDivElement, Props>(
  ({ className, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment hundredTopTablesContent on Content {
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(
              withActionBar: true
              withCoverImage: true
              withCoverImageSize540x360: true
              withCoverImageSize768x768: true
              withHeadline: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    return (
      <Container className={className} ref={reference}>
        <CoverImage>
          <StyledEntityLink reference={content}>
            {providers.coverImage({
              responsiveVariants: {
                desktopUp: "size540x360",
                mobileUp: "size540x360",
                tabletUp: "size768x768",
              },
              withCaption: false,
            })}
          </StyledEntityLink>
        </CoverImage>

        <Headline>
          <StyledEntityLink reference={content}>
            {providers.headline({ preferSocialHeadline: true })}
          </StyledEntityLink>
        </Headline>

        <ActionBar>
          {providers.sponsorTag()}
          {providers.actionBar({
            commentVariant: "reverse",
            isBookmarkVisible: false,
            isCommentsVisible: true,
          })}
        </ActionBar>
      </Container>
    );
  },
);

HundredTopTables.displayName = "HundredTopTables";
