/**
 * @generated SignedSource<<a0ce8e00ecd064f1efb0e034b26462d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type topicSeoTopic$data = {
  readonly description: {
    readonly text: string | null | undefined;
  } | null | undefined;
  readonly entityId: string;
  readonly image: {
    readonly url: string;
  } | null | undefined;
  readonly metaDescription: string | null | undefined;
  readonly name: string;
  readonly urlAlias: string;
  readonly " $fragmentSpreads": FragmentRefs<"hooksUseDeepLinkTagsBase" | "hooksUseDynamicMetaTags">;
  readonly " $fragmentType": "topicSeoTopic";
};
export type topicSeoTopic$key = {
  readonly " $data"?: topicSeoTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"topicSeoTopic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "topicSeoTopic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FormattedValue",
      "kind": "LinkedField",
      "name": "description",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metaDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "OG"
        }
      ],
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": "image(type:\"OG\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "hooksUseDeepLinkTagsBase"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "hooksUseDynamicMetaTags"
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "eb7ae2d6188849b9c7418f507dd1acab";

export default node;
